<template>
  <div class="h-full flex" style="padding: 20px; box-sizing: border-box;">
    <div style="width: 200px; flex-shrink: 0; overflow-y: scroll; margin-right: 16px;" ref="list">
      <div class="info" v-for="movie in movies" :key="movie.id" :id="`movie${movie.id}`">
        <div class="title">
          <h3>{{movie.title}}</h3>
          <!-- <div v-if="dones.indexOf(movie.id) >= 0" style="color: #67C23A;"><i class="el-icon-success"></i></div>
          <div v-if="errors.indexOf(movie.id) >= 0" style="color: #F56C6C;"><i class="el-icon-error"></i></div> -->
        </div>
        <div class="year"><span style="margin-right: 8px;">{{movie.type}}</span>{{movie.year}}</div>
        <div class="release" v-if="movie.releaseDate"><span>上映：</span><date-time :time="movie.releaseDate" format="YYYY-MM-DD"/></div>
        <!-- <div class=""><el-button type="text" size="mini" @click="startOne(movie)">抓取</el-button></div> -->
      </div>
    </div>
    <div style="flex: 1; display: flex; flex-direction: column;">
      <div class="flex">
        <div class="box">
          <div>共有影视剧：{{movies.length}}部</div>
          <div>登录百度：<el-link icon="el-icon-link" @click="openLogin('baidu')">打开</el-link></div>
          <div>登录豆瓣：<el-link icon="el-icon-link" @click="openLogin('douban')">打开</el-link></div>
          <div>需要访问youtube，请打开代理</div>
        </div>
        <div class="box" style="flex:1">
          <div style="margin-top: 20px; margin-bottom: 20px; text-align: center;">
            <el-button type="primary" size="small" @click="restart(records[0].id)" :loading="fetching" v-if="records[0] && records[0].status === 0">继 续 上 次</el-button>
            <el-button type="primary" size="small" @click="start" :loading="fetching" >开 始 爬 取 数 据</el-button>
            <el-button type="warning" size="small" @click="stop" v-if="fetching" >停 止</el-button>
            <div style="margin-top: 4px; font-size: 12px; color: #777;">时间范围：{{dates.start}}-{{dates.end}}</div>
          </div>
        </div>
        <div class="box">
          <div style="margin: 10px 0;">
            <el-button type="default" size="mini" @click="startCover">完善cover</el-button>
            <div style="margin-top: 10px"><el-button type="default" size="mini" @click="startDouyinTopic">抖音topic</el-button></div>
          </div>

        </div>
      </div>

      <div style="flex: 1; min-height: 0">
        <div class="h-full flex">
          <div style="flex:2; margin-right: 10px; height: 100%; overflow-y: scroll;">
            <div class="box">
              <div v-for="(item, key) in messages" :key="key">
                <div style="border-bottom: 1px solid #EBEEF5; margin-bottom: 0px; padding: 8px;">
                  <div class="flex">
                    <div style="width: 90px">
                      <span v-if="fetching">
                        <i v-if="item.done + item.error.length === movies.length" class="el-icon-success"></i>
                        <i v-else class="el-icon-loading"></i>
                      </span>
                      {{key}}
                    </div>
                    <div style="width: 90px">完成：{{item.done}}</div>
                    <div style="width: 90px">报错：{{item.error.length}}</div>
                  </div>
                  <div v-if="item.error.length > 0" style="margin-top: 12px;">
                    <div v-for="(e, index) in item.error" :key="index" style="margin-bottom: 5px; font-size: 12px;">
                      <div>{{e.movie}}</div>
                      <div>{{e.error}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="flex:3; height: 100%; overflow-y: scroll;">
            <el-table :data="records" size="mini" style="width: 100%;" :fit="true">
              <el-table-column label="开启"><date-time slot-scope="scope" show-time :time="scope.row.startAt" /></el-table-column>
              <el-table-column label="结束"><date-time slot-scope="scope" show-time :time="scope.row.endAt" /></el-table-column>
              <el-table-column label="范围" width="180">
                <div slot-scope="scope">
                  <date-time :time="scope.row.start" /> ~ <date-time :time="scope.row.end" />
                </div>
              </el-table-column>
              <el-table-column label="已爬取" prop="counts" width="60">
              </el-table-column>
              <el-table-column label="状态"  width="80">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" icon="el-icon-video-play" style="margin-left: 10px;" @click="restart(scope.row.id)">继续</el-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>

      </div>
    </div>
    <el-dialog
      title="请选择网站"
      :visible.sync="dialogVisible"
      width="30%">
      <div>
        <el-checkbox-group v-model="sites">
          <el-checkbox :label="s" v-for="s in allSites" :key="s"></el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="doStart">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CrawlerIndex',
  data () {
    return {
      dialogVisible: false,
      sites: [],
      allSites: ['iqiyi', 'imdb', 'boxoffice', 'youku', 'tengxun', 'baike', 'douban', 'weibo', 'maoyan', 'baidu', 'youtube', 'mangguo', 'douyin'],
      keyword: '',
      fetching: false,
      // fetchingError: null,
      dates: {
        start: '',
        end: ''
      },
      recordId: null,
      records: [],
      movies: [],
      messages: {
        iqiyi: { done: 0, error: [] },
        imdb: { done: 0, error: [] },
        boxoffice: { done: 0, error: [] },
        youku: { done: 0, error: [] },
        tengxun: { done: 0, error: [] },
        baike: { done: 0, error: [] },
        douban: { done: 0, error: [] },
        weibo: { done: 0, error: [] },
        maoyan: { done: 0, error: [] },
        baidu: { done: 0, error: [] },
        youtube: { done: 0, error: [] },
        mangguo: { done: 0, error: [] },
        douyin: { done: 0, error: [] }
      }
    }
  },
  computed: {
    // movies2: function () {
    //   if (!this.keyword) return this.movies
    //   return this.movies.filter(i => i.title.indexOf(this.keyword) >= 0)
    // }
  },
  methods: {
    startCover () {
      this.$request('/movies/no_covers', { start: this.dates.start, end: this.dates.end })
        .then(res => {
          console.log(res)
          window.electron.send('covers:start', res)
        })
    },
    startDouyinTopic () {
      window.electron.send('douyinTopic:start', this.movies)
    },
    resetMessages () {
      this.messages = {
        iqiyi: { done: 0, error: [] },
        imdb: { done: 0, error: [] },
        boxoffice: { done: 0, error: [] },
        youku: { done: 0, error: [] },
        tengxun: { done: 0, error: [] },
        baike: { done: 0, error: [] },
        douban: { done: 0, error: [] },
        weibo: { done: 0, error: [] },
        maoyan: { done: 0, error: [] },
        baidu: { done: 0, error: [] },
        youtube: { done: 0, error: [] },
        mangguo: { done: 0, error: [] },
        douyin: { done: 0, error: [] }
      }
    },
    scrollTo (movieId) {
      const top = document.getElementById(`movie${movieId}`).offsetTop - 20
      // window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
      this.$refs.list.scrollTo({ top: top, behavior: 'smooth' })
    },
    openLogin (site) {
      window.electron.send('open', site)
    },
    // startOne (movie) {
    //   this.fetching = true
    //   this.fetchingError = null
    //   this.recordId = this.records[0].id
    //   window.electron.send('start', { movies: [movie], start: this.dates.start, end: this.dates.end })
    // },
    doStart () {
      if (this.sites.length === 0) {
        this.$message({ message: '请选择', type: 'error' })
        return
      }
      this.fetching = true
      this.resetMessages()
      this.dialogVisible = false
      window.electron.send('start', { movies: this.movies, start: this.dates.start, end: this.dates.end, sites: this.sites })
    },
    start () {
      this.fetching = true
      this.$request.post('/crawler/records', { start: this.dates.start, end: this.dates.end })
        .then(({ id }) => {
          this.recordId = id
          this.dialogVisible = true
          // window.electron.send('start', { movies: this.movies, start: this.dates.start, end: this.dates.end })
        })
        .catch(err => {
          console.log(err)
          this.fetching = false
        })
    },
    restart (recordId) {
      this.recordId = recordId
      this.getMovies(recordId).then(() => {
        this.dialogVisible = true
        // this.fetching = true
        //
        // this.resetMessages()
        // window.electron.send('start', { movies: this.movies, start: this.dates.start, end: this.dates.end })
      })
    },
    stop () {
      window.electron.send('stop')
    },
    getRecords () {
      this.$request.get('/crawler/records').then(res => {
        this.records = res
      })
    },
    getMovies (recordId) {
      return new Promise((resolve, reject) => {
        this.$request.get('/crawler/movies', { params: { recordId } })
          .then(res => {
            this.movies = res
            // this.summary.total = res.length
            resolve()
          })
          .catch(reject)
      })
    }
  },
  created () {
    window.electron.on('done', (event) => {
      this.fetching = false
      this.recordId = null
      this.getRecords()
    })
    window.electron.on('movie:done', (event, { movie, site, result }) => {
      // console.log(`${site}--${movie}--${JSON.stringify(result)}`)
      this.messages[site].done = this.messages[site].done + 1
      if (!result) return
      this.$request.post('/crawler/data', { movieId: movie.id, recordId: this.recordId, [site]: result })
        .then(() => {
          // this.scrollTo(movie.id)
          // this.dones = [...this.dones, movie.id]
        })
    })
    window.electron.on('movie:error', (event, { site, movie, error }) => {
      console.log('movie:error')
      console.log(`${site}--${movie}--${JSON.stringify(error)}`)
      this.messages[site].error = [...this.messages[site].error, { movie, error }]
    })
    window.electron.on('error', (event, error) => {
      this.$message({ message: error, type: 'error' })
    })
    window.electron.on('covers:done', (event, covers) => {
      console.log('covers:done')
      console.log(covers)
      this.$request.post('/movies/no_covers', { info: covers })
        .then(() => {
          window.alert('完善covers完成')
        })
      // this.messages[site].error = [...this.messages[site].error, { movie, error }]
    })
  },
  mounted () {
    this.getMovies()
    this.getRecords()
    const now = dayjs()
    const s = (Math.floor(now.month() / 3) + 1) * 3 - 1
    // const lastS = s === 1 ? 4 : s - 1
    console.log(Math.floor(now.month() / 3))
    console.log(Math.floor(now.month() / 3) + 1, s, now.year())

    const lastSeason = dayjs(new Date(now.year(), s, 1)).subtract(3, 'month')
    console.log(s, lastSeason.format('YYYY-MM-DD'))
    const start = lastSeason.subtract(2, 'month').format('YYYY-MM-DD')
    const end = lastSeason.add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
    this.dates = {
      start, end
    }
    // console.log(start, end)
    // console.log(lastSeason)
    // lastSeason = lastSeason.subtract(3, 'month')
    // console.log(lastSeason.format('YYYY-MM-DD'))
    // this.$request('/movies/summary').then(res => {
    //   this.summary = res
    // })
  }
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.h-full {
  height: 100%; overflow-y: hidden;
}
.info {
  line-height: 1.4em; font-size: 12px; padding: 8px; background-color: #fff; margin-bottom: 10px;
  span { margin-right: 4px; }
  .title {
    font-size: 14px; font-weight: 600; margin-bottom: 5px; display: flex;
    h3 { flex: 1; }
    div { width: 14px; text-align: center; }
  }
}
.box {
  background-color: #fff; padding: 10px; margin-bottom: 10px;
}
.counts {
  text-align: center; margin-top: 20px;
  .t { color: #777; margin-bottom: 5px; font-size: 13px; }
  .c1 { font-size: 30px ;}
  .c2 { font-size: 30px; }
  .sp { margin: 0 12px; color: #999; font-size: 24px; line-height: 30px; }
}
.summary {
  margin-bottom: 12px;
  span { margin-right:10px; }
}
//.info {
//  margin-bottom: 16px;
//  .count {}
//  .loading { margin-top: 12px; }
//  .act { margin-top: 20px; .el-button { width: 100%; } }
//  .last-time { font-size: 12px; margin-top: 10px; color: #777;  text-align: right; }
//}

</style>
